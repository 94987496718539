import React, { useEffect, useState } from 'react'
import { IoLogoLinkedin } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoMdLogIn } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { subscribeAdd } from '../../../redux/actions/Enquiry.action';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineErrorOutline } from "react-icons/md";

function Footer({ props, border, line }) {

    const [formData, setFormData] = useState({
        email: ''
    })

    const [errors, setErrors] = useState({
        email: ''
    })

    const subscribeData = useSelector((store) => store?.enquiry);
    const dispatch = useDispatch();

    const handleEmail = (e) => {
        e.preventDefault();
        const { email } = formData;
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const newErrors = {
            email: email.trim() ? (emailRegex.test(email) ? '' : 'Please enter a valid Email to continue') : 'Email is required',
        };
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            return;
        } else {
            dispatch(subscribeAdd(formData))
        }
    }

    useEffect(() => {
        if (subscribeData?.SubscribeForm?.success === true) {
            setFormData({
                email: ''
            })
            setErrors({
                email: ''
            })
        }
    }, [subscribeData?.SubscribeForm]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        if (name === 'email') {
            const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            setErrors((prevErrors) => ({ ...prevErrors, email: value.trim() ? (emailRegex.test(value) ? '' : 'Please enter a valid Email to continue') : "Email is requried" }));
        }
    }

    return (
        <>
            <div className={`footer bg-[${props.bgColor}] text-${props.text} ${line}`}>
                <div className='pt-[50px]'>
                    <div className='container'>
                        <div className="row gy-md-3 pb-md-3">
                            <div className="col-lg-3 col-md-6">
                                <div className='pb-2'>
                                    <a href="/"><img src={props.logo} alt='logo' width="200" /></a>
                                    <div className='mt-4'>
                                        <p className='mb-0'>Principal Office</p>
                                        <p className='text-[14px]'> WeWork Forum, DLF Cyber City Rd, DLF Phase 3, Sector 24, Gurugram, Haryana 122002</p>
                                        <p className='underline mb-md-0 underline-offset-2 d-lg-none'>An ISO 9001:2015 Certified Company</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className='pb-2'>
                                    <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Our Offices</p>
                                    <ul className=''>
                                        <li><a href="#" className={`text-${props.text} effect`}>Gurugram</a></li>
                                        <li><a href="#" className={`text-${props.text} effect`}>Noida</a></li>
                                        <li><a href="#" className={`text-${props.text} effect`}>Vanarasi</a></li>
                                        <li><a href="#" className={`text-${props.text} effect`}>Abu Dhabi</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className='pb-2'>
                                    <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Reach Us</p>
                                    <p className='mb-0'>Phone:</p>
                                    <a href="tel:+91 7428867890" className={`text-decoration-underline text-${props.text} decoration-1`}>+91 7428867890</a><br />
                                    <a href="tel:+91 8896100021" className={`text-decoration-underline text-${props.text} decoration-1`}>+91 8896100021</a>
                                    <p className='mb-0'>Email:</p>
                                    <a href="mailto:info@codersbaytech.com" className={`mailto text-decoration-underline text-${props.text} decoration-1`}>info@codersbaytech.com</a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className='pb-2'>
                                    <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Subscribe</p>
                                    <p>Keep up to date with the latest blog, industry trends and technical announcements.</p>
                                    <div className='flex mt-[5px]'>
                                        <input type='email' placeholder='Email Address' value={formData.email} onChange={handleInputChange} name="email" className='self-center p-[10px] rounded-[5px]'></input>

                                        <div className='self-center'>
                                            <p className='mb-0 cursor-pointer' onClick={handleEmail}><IoMdLogIn className={`text-[35px] ml-2 text-[${props.title}]`} /></p>
                                        </div>

                                    </div>
                                    {errors.email && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.email}</p>}
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-lg-block d-none">
                                <p className='underline underline-offset-2'>An ISO 9001:2015 Certified Company</p>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <div className='d-md-block d-none'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Industries</p>
                                        <ul className="menu level-1">
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Energy & Utility" >Energy & Utility</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Retail & E-Commerce">Retail & E-Commerce</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Hospitality & Transportation">Hospitality & Transportation</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Public Sector">Public Sector</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Health Care & Life Science">Health Care & Life Science</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Media & Entertainment">Media & Entertainment</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Banking & Finance Service">Banking & Finance Service</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Education">Education</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/industries" className="effect nav-link" title="Manufacturing">Manufacturing</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='d-block d-md-none border-b border-b-[#212529]'>
                                        <p data-bs-toggle="collapse" href="#collapseExample" className={`text-[${props.title}] md:text-[20px] flex justify-between items-center py-2 font-bold mb-0`}>
                                            <span>Industries</span>
                                            <i className='fa-solid fa-angle-down'></i>
                                        </p>
                                        <div className="collapse visible" id="collapseExample">
                                            <ul className="menu level-1">
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Energy & Utility">Energy & Utility</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Retail & E-Commerce">Retail & E-Commerce</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Hospitality & Transportation">Hospitality & Transportation</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Public Sector">Public Sector</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Health Care & Life Science">Health Care & Life Science</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Media & Entertainment">Media & Entertainment</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Banking & Finance Service">Banking & Finance Service</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Education">Education</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/industries" className="effect nav-link" title="Manufacturing">Manufacturing</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <div className='d-md-block d-none'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Services</p>
                                        <ul className="menu level-1">
                                            <li className="nav-item">
                                                <a href="/software-development" title="Software Development" className="effect nav-link">Software Development</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/mobile-app" title="Mobile App Development" className="effect nav-link">Mobile App Development</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/website-development" className="effect nav-link" title="Website Developments">Website Development</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/AI-ML" className="effect nav-link" title="AI & ML">AI & ML</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/ecommerce" className="effect nav-link" title="eCommerce">eCommerce</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/ERP-CRM" className="effect nav-link" title="ERP & CRM">ERP & CRM</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/blockchain" className="effect nav-link" title="Blockchain">Blockchain</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/marketing" className="effect nav-link" title="Marketing">Marketing</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/emerging-tech" className="effect nav-link" title="Emerging Tech">Emerging Tech
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/cloud" className="effect nav-link nav-link--technology" title="Cloud Engineering">Cloud Engineering</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/hire" title="Hire developers" className="effect nav-link nav-link-https--wwwhcltechcom-consumer-goods-services">Hire developers</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/game" className="effect nav-link" title="Game Development">Game Development</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='d-block d-md-none border-b border-b-[#212529]'>
                                        <p data-bs-toggle="collapse" href="#collapseExample1" className={`text-[${props.title}] md:text-[20px] font-bold mb-0 flex justify-between items-center py-2`}>
                                            <span>Services</span>
                                            <i className='fa-solid fa-angle-down'></i>
                                        </p>
                                        <div className="collapse visible" id="collapseExample1">
                                            <ul className="menu level-1">
                                                <li className="nav-item">
                                                    <a href="/software-development" title="Software Development" className="nav-link nav-link--aerospace-and-defense-services">Software Development</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/mobile-app" title="Mobile App Development" className="nav-link nav-link--aerospace-and-defense-services">Mobile App Development</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/website-development" className="nav-link" title="Website Developments">Website Development</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/AI-ML" className="nav-link" title="AI & ML">AI & ML</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/ecommerce" className="nav-link" title="eCommerce">eCommerce</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/ERP-CRM" className="nav-link" title="ERP & CRM">ERP & CRM</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/blockchain" className="nav-link" title="Blockchain">Blockchain</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/marketing" className="nav-link" title="Marketing">Marketing</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/emerging-tech" className="nav-link" title="Emerging Tech">Emerging Tech
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/cloud" className="nav-link nav-link--technology" title="Cloud Engineering">Cloud Engineering</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/hire" title="Hire developers" className="nav-link nav-link-https--wwwhcltechcom-consumer-goods-services">Hire developers</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/game" className="nav-link" title="Game Development">Game Development</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <div className='d-md-block d-none'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Company</p>
                                        <ul className="quick-links">
                                            <li className="quick-links-list nav-item"><a href="/" className={`effect nav-link text-${props.text}`}>Home</a></li>
                                            <li className="quick-links-list nav-item"><a href="/about-us" className={`effect nav-link text-${props.text}`}>About Us</a></li>
                                            <li className="quick-links-list nav-item"><a href="/careers" className={`effect nav-link text-${props.text}`}>Careers</a></li>
                                            <li className="quick-links-list nav-item"><a href="/contact-us" className={`effect nav-link text-${props.text}`}>Contact Us</a></li>
                                        </ul>
                                    </div>
                                    <div className='d-lg-block d-none mt-3'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Legal</p>
                                        <ul className="quick-links">
                                            <li className="quick-links-list nav-item"><a href="/desclimer" className={`effect nav-link text-${props.text}`}> Disclaimer</a></li>
                                            <li className="quick-links-list nav-item"><a href="/terms-service" className={`effect nav-link text-${props.text}`}>Terms and Conditions</a></li>
                                            <li className="quick-links-list nav-item"><a href="/privacy-policy" className={`effect nav-link text-${props.text}`}>Privacy Policy</a></li>
                                            <li className="quick-links-list nav-item"><a href="/cookie-policy" className={`effect nav-link text-${props.text}`}>Cookie Policy</a></li>
                                            <li className="quick-links-list nav-item"><a href="/refund-policy" className={`effect nav-link text-${props.text}`}>Refund Policy</a></li>
                                        </ul>
                                    </div>
                                    <div className='d-block d-md-none border-b border-b-[#212529]'>
                                        <p data-bs-toggle="collapse" href="#collapseExample5" className={`text-[${props.title}] md:text-[20px] font-bold mb-0 flex justify-between items-center py-2`}>
                                            <span>Company</span>
                                            <i className='fa-solid fa-angle-down'></i>
                                        </p>
                                        <div className="collapse visible" id="collapseExample5">
                                            <ul className="quick-links">
                                                <li className="quick-links-list nav-item"><a href="/" className={`text-${props.text} nav-link`}>Home</a></li>
                                                <li className="quick-links-list nav-item"><a href="/about-us" className={`text-${props.text} nav-link`}>About Us</a></li>
                                                <li className="quick-links-list nav-item"><a href="/careers" className={`text-${props.text} nav-link`}>Careers</a></li>
                                                <li className="quick-links-list nav-item"><a href="/contact-us" className={`text-${props.text} nav-link`}>Contact Us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-lg-block d-none"></div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <div className='d-md-block d-none'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Resources</p>
                                        <ul className="quick-links">
                                            {/* <li className="quick-links-list"><a href="/blog" className={`effect text-${props.text}`}>Blog</a></li> */}
                                            {/* <li className="quick-links-list"><a href="#" className={`effect text-${props.text}`}>Help Center</a></li> */}
                                            <li className="quick-links-list"><a href="/partner" className={`effect text-${props.text}`}>Partner Program</a></li>
                                        </ul>
                                    </div>
                                    <div className='d-block d-md-none border-b border-b-[#212529]'>
                                        <p data-bs-toggle="collapse" href="#collapseExample6" className={`text-[${props.title}] md:text-[20px] font-bold mb-0 flex justify-between items-center py-2`}>
                                            <span>Resources</span>
                                            <i className='fa-solid fa-angle-down'></i>
                                        </p>
                                        <div className="collapse visible" id="collapseExample6">
                                            <ul className="quick-links">
                                                {/* <li className="quick-links-list"><a href="/blog" className={`text-${props.text}`}>Blog</a></li> */}
                                                {/* <li className="quick-links-list"><a href="#" className={`text-${props.text}`}>Help Center</a></li> */}
                                                <li className="quick-links-list"><a href="/partner" className={`text-${props.text}`}>Partner Program</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <div className='d-md-block d-none'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Account</p>
                                        <ul className="quick-links">
                                            {/* <li className="quick-links-list nav-item"><a href="/login" className={`nav-link effect text-${props.text}`}>Sign in</a></li> */}
                                            {/* <li className="quick-links-list nav-item"><a href="/sign-up" className={`nav-link effect text-${props.text}`}>Sign up</a></li> */}
                                            {/* <li className="quick-links-list nav-item"><a href="/checkout" className={`nav-link effect text-${props.text}`}>Checkout Now</a></li> */}
                                            <li className="quick-links-list nav-item"><a href="/payment" className={`nav-link effect text-${props.text}`}>Payment</a></li>
                                        </ul>
                                    </div>
                                    <div className='d-block d-md-none border-b border-b-[#212529]'>
                                        <p data-bs-toggle="collapse" href="#collapseExample7" className={`text-[${props.title}] md:text-[20px] font-bold mb-0 flex justify-between items-center py-2`}>
                                            <span>Account</span>
                                            <i className='fa-solid fa-angle-down'></i>
                                        </p>
                                        <div className="collapse visible" id="collapseExample7">
                                            <ul className="quick-links">
                                                {/* <li className="quick-links-list nav-item"><a href="/login" className={`nav-link text-${props.text}`}>Sign in</a></li> */}
                                                {/* <li className="quick-links-list nav-item"><a href="/sign-up" className={`nav-link text-${props.text}`}>Sign up</a></li> */}
                                                {/* <li className="quick-links-list nav-item"><a href="/checkout" className={`nav-link text-${props.text}`}>Checkout Now</a></li> */}
                                                <li className="quick-links-list nav-item"><a href="/payment" className={`nav-link effect text-${props.text}`}>Payment</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <div className='d-lg-none d-md-block d-none'>
                                        <p className={`text-[${props.title}] md:text-[20px] font-bold mb-0`}>Legal</p>
                                        <ul className="quick-links">
                                            <li className="quick-links-list"><a href="/desclimer" className={`effect text-${props.text}`}> Disclaimer</a></li>
                                            <li className="quick-links-list"><a href="/terms-service" className={`effect text-${props.text}`}>Terms and Conditions</a></li>
                                            <li className="quick-links-list"><a href="/privacy-policy" className={`effect text-${props.text}`}>Privacy Policy</a></li>
                                            <li className="quick-links-list"><a href="/cookie-policy" className={`effect text-${props.text}`}>Cookie Policy</a></li>
                                            <li className="quick-links-list"><a href="/refund-policy" className={`effect text-${props.text}`}>Refund Policy</a></li>
                                        </ul>
                                    </div>
                                    <div className='d-block d-md-none '>
                                        <p data-bs-toggle="collapse" href="#collapseExample8" className={`text-[${props.title}] md:text-[20px] font-bold mb-0 flex justify-between items-center py-2`}>
                                            <span>Legal</span>
                                            <i className='fa-solid fa-angle-down'></i>
                                        </p>
                                        <div className="collapse visible" id="collapseExample8">
                                            <ul className="quick-links">
                                                <li className="quick-links-list"><a href="/desclimer" className={`text-${props.text}`}> Disclaimer</a></li>
                                                <li className="quick-links-list"><a href="/terms-service" className={`text-${props.text}`}>Terms and Conditions</a></li>
                                                <li className="quick-links-list"><a href="/privacy-policy" className={`text-${props.text}`}>Privacy Policy</a></li>
                                                <li className="quick-links-list"><a href="/cookie-policy" className={`text-${props.text}`}>Cookie Policy</a></li>
                                                <li className="quick-links-list"><a href="/refund-policy" className={`text-${props.text}`}>Refund Policy</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='py-4 border-t border-t-[#363b41]' style={{ borderBottom: `${border}` }}>
                            <div>
                                <div className='d-md-flex gap-2 items-center justify-between text-center'>
                                    {/* <Language props={props} /> */}
                                    <div className="flex items-center justify-center gap-3">
                                        <div>
                                            <img src="/assets/images/msme.png" className='lg:w-[120px] w-[100px] msme' alt="msme" />
                                        </div>
                                        <div>
                                            <img src="/assets/images/startup.png" className='lg:w-[130px] w-[100px]' alt="startup" />
                                        </div>
                                        <div>
                                            <a href="https://ecosystem.thetechisland.org/companies/codersbay_technologies" rel="noreferrer" target='_blank'>
                                                <img src="/assets/images/tech.svg" className='lg:w-[130px] w-[100px]' alt="tech" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-center mt-md-0 mt-4'>
                                        <div className='text-[25px] pr-[15px]'>
                                            <a href="https://www.linkedin.com/company/codersbay" target='_blank' rel="noreferrer" className={`text-${props.text}`}><IoLogoLinkedin /></a>
                                        </div>
                                        <div className='text-[25px] pr-[15px]'>
                                            <a href="https://www.facebook.com/codersbaytech/" target='_blank' rel="noreferrer" className={`text-${props.text}`}><FaFacebookSquare /></a>
                                        </div>
                                        <div className='text-[25px] pr-[15px]'>
                                            <a href="https://www.instagram.com/codersbaytech/" target='_blank' rel="noreferrer" className={`text-${props.text}`}><FaInstagram /></a>
                                        </div>
                                        <div className='text-[25px] pr-[15px]'>
                                            <a href="https://x.com/codersbaytech" target='_blank' rel="noreferrer" className={`text-${props.text}`}><FaXTwitter /></a>
                                        </div>
                                        <div className='text-[25px] pr-[15px]'>
                                            <a href="mailto:info@codersbaytech.com" className={`text-${props.text}`}><HiOutlineMail /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`bg-[${props.copyright}] py-[20px]`}>
                        <div className="container">
                            <p className='text-center d-lg-flex items-center justify-center text-[#fff] mb-0'><span className='inline-flex items-center'>Crafted With <FaHeart className='mx-2' /> in India.</span> Copyright © 2023 Codersbay Technologies. All Rights Reserved.
                                <a href="https://www.dmca.com/compliance/codersbay.co" target='_blank' rel="noreferrer" title="DMCA.com Protection Status" className="dmca-badge ms-2"> <img src="https://images.dmca.com/Badges/dmca-badge-w150-5x1-11.png?ID=fd10088a-60ef-4be6-9541-fbbc245ad4c3" className='mx-auto mt-2 mt-lg-0' alt="DMCA.com Protection Status" /></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Footer