import React, { useEffect, useState } from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { quoteAdd } from '../redux/actions/Enquiry.action';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineErrorOutline } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';

const GetQuote = ({ show, handleClose }) => {

    const [formData, setFormData] = useState({
        name: '',
        number: '',
        businessEmail: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        services: '',
        message: ''
    })

    const [errors, setErrors] = useState({
        name: '',
        number: '',
        businessEmail: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        services: '',
    })

    const quoteData = useSelector((store) => store?.enquiry);
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'name') {
            setErrors((prevErrors) => ({ ...prevErrors, name: value.trim() ? '' : "All field is requried" }));
        } else if (name === 'number') {
            setErrors((prevErrors) => ({ ...prevErrors, number: value.trim() ? '' : "All field is requried" }));
        } else if (name === 'businessEmail') {
            const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
            setErrors((prevErrors) => ({
                ...prevErrors, businessEmail: value.trim() ? (emailRegex.test(value) ? '' : 'Please enter a valid Email to continue') : 'All field is requried'
            }));
        } else if (name === 'companyName') {
            setErrors((prevErrors) => ({ ...prevErrors, companyName: value.trim() ? '' : "All field is requried" }));
        } else if (name === 'companySize') {
            setErrors((prevErrors) => ({ ...prevErrors, companySize: value.trim() ? '' : "All field is requried" }));
        } else if (name === 'jobTitle') {
            setErrors((prevErrors) => ({ ...prevErrors, jobTitle: value.trim() ? '' : "All field is requried" }));
        } else if (name === 'services') {
            setErrors((prevErrors) => ({ ...prevErrors, services: value.trim() ? '' : "All field is requried" }))
        }
    }

    const handleContact = (e) => {

        e.preventDefault();
        const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
        const { name, number, businessEmail, companyName, companySize, jobTitle, services } = formData;
        const newErrors = {
            name: name.trim() ? '' : 'All field is requried',
            number: number.trim() ? '' : 'All field is requried',
            businessEmail: businessEmail.trim() ? (emailRegex.test(formData.businessEmail) ? '' : 'Please enter a valid Email to continue') : 'All field is requried',
            companyName: companyName.trim() ? '' : 'All field is requried',
            companySize: companySize.trim() ? '' : 'All field is requried',
            jobTitle: jobTitle.trim() ? '' : 'All field is requried',
            services: services.trim() ? '' : 'All field is requried',
        }
        setErrors(newErrors);
        if (Object.values(newErrors).some(error => error)) {
            return;
        } else {
            setIsSubmitting(true);
            dispatch(quoteAdd(formData)).then(() => {
                setIsSubmitting(false)
                handleClose()
            });
        }
    }

    const handlePhoneChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            number: value
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            number: value.trim() ? '' : "All field is requried"
        }));
    }

    useEffect(() => {
        if (quoteData?.QuoteForm?.success === true) {
            setFormData({
                name: '',
                number: '',
                businessEmail: '',
                companyName: '',
                companySize: '',
                jobTitle: '',
                services: '',
                message: ''
            })
        }
    }, [quoteData?.QuoteForm]);

    return (
        <div className={`get_quote`}>
            <Modal show={show} onHide={handleClose} className='space'>
                <Modal.Header closeButton>
                    <Modal.Title>Let’s get started!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className={`contact_form bg-white rounded-[20px] p-3`}>
                        <div className='row g-3 '>
                            <div className="col-md-12">
                                <div className="">
                                    <label for="floatingInput" className='mb-[5px]'>Full Name*</label>
                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleInputChange} id="floatingInput" placeholder="e.g. James Scalf" autoComplete='off' />
                                    {errors.name && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.name}</p>}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="">
                                    <label for="floatingInput" className='mb-[5px]'>Phone no*</label>
                                    <PhoneInput
                                        className="number w-100"
                                        country={"in"}
                                        name="number"
                                        value={formData.number}
                                        onChange={handlePhoneChange}
                                        placeholder='xxx-xxx-xxxx'
                                    />
                                    {errors.number && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.number}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <label for="floatingInput" className='mb-[5px]'>Email*</label>
                                    <input type="email" className="form-control" value={formData.businessEmail} onChange={handleInputChange} name="businessEmail" id="floatingInput" placeholder="e.g. james.scalf@company.com" autoComplete='off' />
                                    {errors.businessEmail && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.businessEmail}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <label for="floatingInput" className='mb-[5px]'>Company/Project Name*</label>
                                    <input type="text" className="form-control" value={formData.companyName} onChange={handleInputChange} id="floatingInput" name="companyName" placeholder="e.g. Company/Project" autoComplete='off' />
                                    {errors.companyName && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.companyName}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label for="floatingInput" className='mb-[5px]'>Company Size*</label>
                                <select className="form-select" id="floatingSelect" name="companySize" value={formData.companySize} onChange={handleInputChange} aria-label="Floating label select example">
                                    <option disabled>Company Size</option>
                                    <option>Self-employed</option>
                                    <option>1 - 10</option>
                                    <option>11 - 50</option>
                                    <option>51 - 200</option>
                                    <option>201 - 500</option>
                                    <option>501 - 1000</option>
                                    <option>1001+</option>
                                </select>
                                {errors.companySize && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.companySize}</p>}
                            </div>
                            <div className="col-12">
                                <label htmlFor="" className='mb-2'>Job title</label>
                                <div className="row">
                                    <div className="col-lg-4 col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="jobTitle" value="Entrepreneur" onChange={handleInputChange} id="flexRadioDefault1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">Entrepreneur</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="jobTitle" value="Manager" onChange={handleInputChange} id="flexRadioDefault2" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">Manager</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="jobTitle" value="Director" onChange={handleInputChange} id="flexRadioDefault3" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault3">Director</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="jobTitle" value="C-Level" onChange={handleInputChange} id="flexRadioDefault4" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault4">C-Level</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="jobTitle" value="Student" onChange={handleInputChange} id="flexRadioDefault5" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault5">Student</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="jobTitle" value="Other" onChange={handleInputChange} id="flexRadioDefault6" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault6">Other</label>
                                        </div>
                                    </div>
                                </div>
                                {errors.jobTitle && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.jobTitle}</p>}
                            </div>
                            <div className="col-12">
                                <label for="floatingInput" className='mb-[5px]'>Services*</label>
                                <select className="form-select" name="services" value={formData.services} onChange={handleInputChange} id="floatingSelect" aria-label="Floating label select example">
                                    <option disabled value="">Services</option>
                                    <option selected>Software Development</option>
                                    <option>Mobile App Development</option>
                                    <option>Website Development</option>
                                    <option>AI & ML</option>
                                    <option>eCommerce</option>
                                    <option>ERP and CRM</option>
                                    <option>Blockchain</option>
                                    <option>Marketing</option>
                                    <option>Emerging Tech</option>
                                    <option>Cloud Engineering</option>
                                    <option>Hire developers</option>
                                    <option>Game Development</option>
                                    <option>Other</option>
                                </select>
                                {errors.services && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.services}</p>}
                            </div>
                            <div className="col-md-12">
                                <div className="">
                                    <label htmlFor="" className='mb-[5px]'>Comments/Message:</label>
                                    <textarea className="form-control resize-none" name="message" value={formData.message} onChange={handleInputChange} placeholder="Write a Message..." id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                    {errors.message && <p className='text-danger flex items-center gap-1 mb-0 text-sm'><MdOutlineErrorOutline />{errors.message}</p>}
                                </div>
                            </div>

                            <div className={`col-12 text-black`}>
                                <p className='text-[14px]'>For further details on how your personal data will be processed and how your consent can be managed, refer to the <a href="/privacy-policy" className={`text-decoration-underline text-[#271A62]`}>Privacy Policy</a> and <a href="/terms-service" className={`text-decoration-underline text-[#271A62]`}>Terms and Conditions</a> </p>
                                {/* <p className='text-[14px]'>Our pricing starts with INR 9999*</p> */}
                                <p className='font-[700] text-[14px]'>*Mandatory fields</p>
                            </div>
                            <div className="col-12">
                                <button type="submit" onClick={handleContact} className={`bg-[#271A62] text-[#fff] px-4 py-2 rounded-lg`}>{isSubmitting ? 'Please wait' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default GetQuote